import 'alpinejs'

require('../bootstrap');

window.$ = window.jQuery = require('jquery');
window.Swal = require('sweetalert2');
window.moment = require('moment-timezone');
window.select2 = require('select2');

// CoreUI
require('@coreui/coreui');

// Boilerplate
require('../plugins');
